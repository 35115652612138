import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

function OptionsModal({
  setShowOptionsModal,
  showOptionsModal,
  setSell,
  setAccepted,
  page,
  halfPrice,
  defCurrency,
  referral,
  sell,
  setPrice,
  setAmountToPay,
  amountToPay,
  priceData,
  verifyCouponCode,
  setPriceData,
  formik,
  setCouponValue,
  couponValue,
  hideOptionModal,
  modalCleanup,
}) {
  // useEffect(() => {
  //   if (page) {
  //     // for (let a = 1; a < 3 + 1; a++) {
  //     //   if (document.getElementById("button" + a) !== null) {
  //     //     const element = document.getElementById("button" + a);
  //     //     element.addEventListener("click", () => setSell(a));
  //     //   }
  //     // }
  //     for (let a = 1; a < 3 + 1; a++) {
  //       if (document.getElementById("price" + a) !== null) {
  //         let pp = Number(page["price" + a] || 0);
  //         if (halfPrice && pp > 1) pp /= 2;
  //         const element = document.getElementById("price" + a);
  //         element.innerHTML = pp.toLocaleString("en-US", {
  //           style: "currency",
  //           currency: defCurrency || "USD",
  //           minimumFractionDigits: 0,
  //         });
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page, referral, halfPrice, defCurrency]);

  useEffect(() => {
    if (sell) {
      let pp = Number(page["price" + sell] || 0);
      if (halfPrice && pp > 1) pp /= 2;
      setPrice(pp);
      setAmountToPay(
        referral && amountToPay && priceData === "price" + sell
          ? amountToPay
          : pp
      );
      setPriceData("price" + sell);
      if (formik.values.couponCode) verifyCouponCode(pp);
      setCouponValue(
        referral && couponValue && priceData === "price" + sell
          ? couponValue
          : 0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sell, halfPrice]);
  return (
    <>
      <Modal
        show={showOptionsModal}
        onHide={modalCleanup}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // className="bg-light"
      >
        <Modal.Body>
          <section className="py-lg-4 py-xxl-4 py-4 bg-light">
            <div className="container">
              <div className="row">
                <div className="text-center mb-lg-4 mb-3">
                  <h2 className="fw-bold mb-3">
                    GET ALL SPECIAL PROGRAMS FOR COHORT 9
                  </h2>
                  <p className="mb-0 px-lg-10 px-5">
                    Save 93% by choosing ALL SPECIAL PROGRAMS! Get 12 months of
                    premium videos from all BLACKFORCE COHORT for the price of
                    2. Hurry—this limited-time offer won’t last!
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-10 offset-xl-1 col-md-12 col-12">
                  <div className="row">
                    <div className="col-xl-6 col-md-12 col-lg-6 col-12">
                      {/*card*/}
                      <div className="card">
                        {/*card-body*/}
                        <div className="card-body p-5">
                          {/*content*/}
                          <span className="text-danger text-uppercase font-weight-bold">
                            ONE SPECIAL PROGRAM
                          </span>
                          <div className="d-flex align-items-center mt-5">
                            <h2>
                              $500{" "}
                              {/* <span
                                style={{
                                  fontSize: "14.6667px",
                                }}
                              >
                                /month
                              </span> */}
                            </h2>
                            {/* <span className="ms-1">(USD)</span> */}
                          </div>
                          <p className="mb-5">
                            You can ONLY take ONE special program offered in
                            this COHORT 9 and access only the previous recorded
                            videos for that Special Program.
                          </p>

                          <div className="mt-5 d-grid">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                hideOptionModal();
                                setAccepted(true);
                                setSell(1);
                              }}
                              // id="button1"
                              disabled=""
                            >
                              Subscribe Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-12 col-lg-6 col-12">
                      {/*card*/}
                      <div className="card">
                        {/*card body*/}
                        <div className="card-body p-5">
                          {/*content*/}
                          <span className="text-uppercase text-danger font-weight-bold">
                            ALL SPECIAL PROGRAM
                          </span>
                          <div className="d-flex align-items-center mt-5">
                            <h2>$1000</h2>
                            {/* <span className="ms-1">(USD)</span> */}
                          </div>
                          <p className="mb-0">
                            You can take all special programs we offer in this
                            COHORT 9 and access all previous cohort videos at
                            Blackforce.
                          </p>

                          <div className="mt-5 d-grid">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              // data-toggle="modal"
                              // data-target="#formModal"
                              onClick={() => {
                                // document.getElementById("pricing").click();
                                hideOptionModal();
                                setAccepted(true);
                                setSell(2);
                              }}
                              // id="button2"
                              disabled=""
                            >
                              Subscribe Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 text-center mt-3">
                      <p
                        className="text-danger"
                        style={{
                          color: "rgb(0, 0, 0)",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "14.6667px",
                          whiteSpaceCollapse: "preserve",
                        }}
                      >
                        Payments and non refundable. Accounts are not
                        transferable. Note: Not all special programs are
                        guaranteed. Minimum of 10 Students are required for a
                        special program to hold.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OptionsModal;
