export const page = {
  business_id: 23,
  currency: "USD",
  html: "",
  id: 15,
  logo: "https://s3.amazonaws.com/teachnow.io/TeachNow-Dev/20231230131704-20220127130808-logo-white.png",
  price1: "25",
  price2: "250",
  price3: "25",
  status: true,
  template: "pricing",
  title: "Blackforce Inc. (Canada)",
  url: "canada",
  user_id: "02b4957e-e5ab-4ad9-9de5-cf439c8d29f7",
};
