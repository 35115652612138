import React from "react";

function TermsMessage({ setShowUserForm, setAccepted }) {
  return (
    <>
      <div className="modal-header">
        <h4 className="modal-title m-auto" id="formModalLabel">
          Terms & Conditions
        </h4>
      </div>

      <div className="modal-body mx-5">
        <h5>Dear Prospective Special Program Student,</h5>

        <p>
          We appreciate your interest in the Blackforce Special Program. Before
          you proceed to make any payment, we urge you to read and agree to the
          program details outlined below. It is important to understand all the
          information provided before moving forward.
        </p>

        <p>
          The Blackforce Special Programs are designed with courses that address
          the current market needs, aiming specifically at professionals with
          experience in the industry.
        </p>
        <p>
          In addition, to partake in Blackforce's IT learning environment, you
          should have a basic knowledge of computer operations, proficiency in
          English, and the capacity to follow written instructions. Our
          facilitators will presume you have these skills.
        </p>
        <p>
          By completing your registration and payment, you affirm that you have
          the skills and experience necessary for engaging effectively in class
          discussions, asking pertinent questions, and comprehending the
          materials based on your professional background.
        </p>
        <p>See specific special Program Details:</p>
        <ol>
          <li className="m-3">
            <b>Time Commitment:</b> A minimum of 1.5 hours per session or 6
            hours weekly (Monday - Thursday) for live classes is expected.
            Additional time may be required for individual or group projects.
          </li>
          <li className="m-3">
            <b>Live Training Sessions:</b> We offer live classes and recorded
            videos for later access. If you cannot attend the live sessions,
            recorded videos are available for self-paced study. YOU WILL ONLY
            HAVE ACCESS to the specific special programs you enrolled into and
            NOT ALL THE SPECIAL PROGRAMS{" "}
            <span className="text-danger">
              UNLESS YOU HAVE PAID FOR ALL ACCESS TO SPECIAL PROGRAM
            </span>
          </li>
          <li className="m-3">
            <b>Tutorial Sessions:</b> We provide optional tutorials for extra
            support, particularly for those facing challenges. These sessions
            are not mandatory and are scheduled on weekdays or weekends.
          </li>
          <li className="m-3">
            <b>One-on-One Mentorship:</b> Available upon request for
            personalized assistance at an additional cost. If you encounter
            difficulties that your group leader cannot address, you can reach
            out to a mentor for assistance or our support team at{" "}
            <a href="mailto:support@blackforce.ca">support@blackforce.ca</a> For
            confidential matters or health issues, please reach out to our
            training manager directly.
          </li>
          <li className="m-3">
            <b>Payment and Refund Policy:</b> All course or program payments are
            final, with no refunds or transfers allowed. Access to session
            videos is granted for one year, with a possible extension through
            the CPD Program for Blackforce professional members.
          </li>
          <li className="m-3">
            <b>Enrollment Instructions and Communication:</b> After payment, you
            will receive a receipt, enrollment instructions, and access to a
            dedicated communication platform. It is crucial to maintain a
            consistent email and identity for program communications.
          </li>
        </ol>
        <p>
          Please click the <b>"I accept terms and conditions"</b> button to
          proceed with payment. We look forward to your success in the program.
        </p>
        <p>
          Sincerely,
          <br />
          Babatope
        </p>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          // data-dismiss="modal"
          // id="closeFormModal"
          onClick={() => {
            setAccepted(false);
            setShowUserForm(false);
          }}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={() => setShowUserForm(true)}
        >
          I accept terms and conditions
        </button>
      </div>
    </>
  );
}

export default TermsMessage;
