import React from "react";
import PricingCard from "../utils/PricingCard";
import data from "../utils/data.json";
import "../../Associate/components/pricing.css";

export default function Pricing({ amountToPay, setShowOptionsModal }) {
  return (
    <>
      {" "}
      <div className="row" id="pricing">
        <div className="col-lg-2 col-md-2 col-12"></div>
        <div className="col-lg-7 col-md-7 col-12">
          <div className="card rounded-lg mb-6 ">
            <div className="card-body p-lg-6 p-md-3">
              <h4 className="mb-3 text-primary">Blackforce Special Program</h4>
              <span className="h1">
                <span id="price1">$500</span>
              </span>
              <p className="mb-0 text-success font-weight-bolder">
                Live training + projects + 12 months access to videos
              </p>
            </div>
            <div className="card-body pb-6 pt-0 px-lg-6 px-md-3 mobile-font-size">
              <ol>
                <li className="mb-2">
                  <b>
                    Sales Cloud, Service Cloud & Artificial Intelligence - March
                    2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Artificial Intelligence & Integration Development - April
                    2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Certified Business Analysis Professional (CBAP) with
                    Salesforce AI April 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Data Analytics, Tableau & Microsoft Power BI with Salesforce
                    AI - April 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Platform Developer with Salesforce AI - April
                    2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Cybersecurity Incident Responder with Salesforce AI - April
                    2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    SF. Administrator with On Demand Videos Sales and Service
                    Cloud - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Health Cloud with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Financial Services Cloud with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Nonprofit Cloud with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Marketing Cloud Administrator with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce DevOps Engineering with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Administrator with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Education Cloud with Salesforce Artificial
                    Intelligence - June 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Integration Architect and Artificial Intelligence
                    - June 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Quality Assurance Testing and Artificial
                    Intelligence - June 2025
                  </b>
                </li>
              </ol>
              {/* </ul> */}
              <button
                type="button"
                className="btn btn-primary btn-block mt-6 mb-3"
                onClick={() => setShowOptionsModal(true)}
                // id="button1"
              >
                Subscribe Now
              </button>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-2 col-md-2 col-12">
          <div style={{ visibility: "hidden" }}>
            <span id="price2">CA$1000</span>
            <button
              type="button"
              className="btn btn-primary btn-block mt-6 mb-3"
              onClick={() => setShowOptionsModal(true)}
              id="button2"
            >
              Subscribe Now
            </button>
          </div>
        </div> */}

        <div className="card-body pb-6 pt-0 px-lg-6 px-md-3">
          <p className="mb-0 font-14" style={{ textAlign: "justify" }}>
            <span id="docs-internal-guid-a206f14e-7fff-df39-07f4-994dd646de3c">
              <span
                style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "14.6667px",
                  whiteSpaceCollapse: "preserve",
                }}
              >
                Our courses may run concurrently, offering access to a variety
                of training options. Some courses are only available as recorded
                videos. As a standard or professional subscriber, you'll have
                complete access to all organized recorded training videos
                provided by BlackForce. Basic Plus subscribers do not have
                access to LIVE Classes but can access ALL Videos from the last
                year (not including the current or the immediate previous
                cohort). Basic Plus subscription is only available once the
                cohort registration ends. Video subscriptions are available for
                12 months. The Blackforce Special Programs consist of carefully
                selected courses designed to meet the demands of the current
                market, specifically targeting seasoned professionals. These
                programs are free for Blackforce CPD Members. If you are not a
                CPD member at Blackforce, you will be given consideration to
                join this special program at a fee. You will only gain access to
                one special program at a time in a training period. *This may be
                offered in this cohort or not. Specialized areas within
                Salesforce, like CPQ, Non-Profit, Field Service, Financial
                Service Cloud, and Experience Cloud, are optional based on your
                interests and requirements. Note that not all certification
                preparation is covered, but we provide guidance for relevant
                certifications. Your projects will be reviewed during class
                sessions for feedback and guidance from instructors. BlackForce
                Continuing Professional Development (CPD) extends learning
                beyond the training program. As a professional member, upon
                successful completion, including the internship, relevant work
                experience can be added to your resume (references require
                completion of the work experience program). All prices are in
                CAD. Review this disclaimer carefully before subscribing to the
                BlackForce program.
              </span>
            </span>
            <br />
          </p>
        </div>
      </div>
    </>
  );
}
