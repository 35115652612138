import React, { useEffect, useState } from "react";
import TermsMessage from "./TermsMessage";
import { Modal } from "react-bootstrap";

function TermsCondition({
  accepted,
  formik,
  priceData,
  setAccepted,
  getInputClasses,
  countries,
  states,
  amountToPay,
  standard,
  professional,
  couponValue,
  verifyCouponCode,
  price,
  page,
  coup,
  selectedState,
  loading,
  defCurrency,
  modalCleanup,
  getCouponCode,
}) {
  const couponCode = getCouponCode();

  useEffect(() => {
    if (couponCode && price && accepted) {
      // document.getElementById("couponCode11").click();
    }
  }, [couponCode, price, accepted]);

  const [showUserForm, setShowUserForm] = useState(false);
  return (
    <>
      <Modal
        show={accepted}
        onHide={modalCleanup}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // className="bg-light"
      >
        {!showUserForm ? (
          <>
            <TermsMessage
              setShowUserForm={setShowUserForm}
              setAccepted={setAccepted}
            />
          </>
        ) : (
          <>
            <div className="modal-header">
              <h4 className="modal-title" id="formModalLabel">
                User Details
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                {" "}
                <div className="row">
                  <div className="mb-3 col-md-6 col-12">
                    <label htmlFor="firstName" className="form-label">
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${getInputClasses("firstName")}`}
                      id="validationCustom01"
                      placeholder="First Name"
                      required
                      {...formik.getFieldProps("firstName")}
                    />
                    <div className="invalid-feedback">
                      {formik.errors["firstName"]}
                    </div>
                  </div>
                  <div className="mb-3 col-md-6 col-12">
                    <label htmlFor="firstName" className="form-label">
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${getInputClasses("lastName")}`}
                      id="validationCustom01"
                      placeholder="Last Name"
                      required
                      {...formik.getFieldProps("lastName")}
                    />
                    <div className="invalid-feedback">
                      {formik.errors["lastName"]}
                    </div>
                  </div>
                  <div className="mb-3 col-md-6 col-12">
                    <label htmlFor="firstName" className="form-label">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${getInputClasses("email")}`}
                      id="validationCustom01"
                      placeholder="Email"
                      required
                      {...formik.getFieldProps("email")}
                    />
                    <div className="invalid-feedback">
                      {formik.errors["email"]}
                    </div>
                  </div>
                  <div className="mb-3 col-md-6 col-12">
                    <label htmlFor="firstName" className="form-label">
                      Confirm Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${getInputClasses(
                        "confirm_email"
                      )}`}
                      id="validationCustom01"
                      placeholder="Confirm Email"
                      required
                      {...formik.getFieldProps("confirm_email")}
                    />
                    <div className="invalid-feedback">
                      {formik.errors["confirm_email"]}
                    </div>
                  </div>
                  <div className="mb-3 col-md-6 col-12">
                    <label htmlFor="firstName" className="form-label">
                      Country<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      {...formik.getFieldProps("country")}
                      required
                    >
                      <option>-- Select One --</option>
                      {countries.map((country, key) => (
                        <option key={key} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {formik.errors["country"]}
                    </div>
                  </div>
                  <div className="mb-3 col-md-6 col-12">
                    <label htmlFor="firstName" className="form-label">
                      Province<span className="text-danger">*</span>
                    </label>
                    {states && states.length ? (
                      <select
                        className={`form-select ${getInputClasses("province")}`}
                        id="validationCustom01"
                        placeholder="Province"
                        {...formik.getFieldProps("province")}
                        required
                      >
                        <option value="">-- Select Province --</option>
                        {states.map((state) => (
                          <option key={state.state} value={state.state}>
                            {state.state}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        className={`form-control ${getInputClasses(
                          "province"
                        )}`}
                        id="validationCustom01"
                        placeholder="Province"
                        {...formik.getFieldProps("province")}
                        required
                      />
                    )}
                    <div className="invalid-feedback">
                      {formik.errors["province"]}
                    </div>
                  </div>
                  <div className="mb-3 col-md-6 col-12">
                    <label htmlFor="firstName" className="form-label">
                      City<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${getInputClasses("city")}`}
                      id="validationCustom01"
                      placeholder="City"
                      {...formik.getFieldProps("city")}
                      required
                    />
                    <div className="invalid-feedback">
                      {formik.errors["city"]}
                    </div>
                  </div>
                  <div className="mb-3 col-md-6 col-12">
                    <label htmlFor="firstName" className="form-label">
                      Phone Number<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${getInputClasses(
                        "phoneNumber"
                      )}`}
                      id="validationCustom01"
                      placeholder="Phone Number"
                      {...formik.getFieldProps("phoneNumber")}
                      required
                    />
                    <div className="invalid-feedback">
                      {formik.errors["phoneNumber"]}
                    </div>
                  </div>
                  <div className="mb-3 col-12 col-md-7 mb-4 m-auto">
                    <center>
                      {" "}
                      <label htmlFor="source" className="form-label">
                        How did you hear about us
                        <span className="text-danger">*</span>
                      </label>
                    </center>

                    <select
                      className={`form-select ${getInputClasses("source")}`}
                      {...formik.getFieldProps("source")}
                    >
                      <option value="">-- Select an option--</option>
                      <option>Facebook</option>
                      <option>Twitter</option>
                      <option>Instagram</option>
                      <option>LinkedIn</option>
                      <option>Google Search</option>
                      <option>Telegram Group</option>
                      <option>WhatsApp Group</option>
                      <option>Email Newsletter</option>
                      <option>I was referred by a friend</option>
                      <option>Others not listed here</option>
                    </select>
                  </div>
                  <div className="mb-3 col-12 col-md-7 mb-4 m-auto">
                    <center>
                      Select Program you are interested in{" "}
                      <span className="text-danger">*</span>
                    </center>
                    <select
                      className={`form-select ${getInputClasses("program")}`}
                      {...formik.getFieldProps("program")}
                    >
                      <option value="">-- Select Program --</option>
                      {priceData === "price1" ? (
                        <>
                          {standard.map((entity) => (
                            <option key={entity.id}>{entity.title}</option>
                          ))}
                        </>
                      ) : priceData === "price2" ? (
                        <>
                          <option key="117">ALL SPECIAL PROGRAMS - SP9</option>
                        </>
                      ) : priceData === "price3" ? (
                        <optgroup label="Professional Programs">
                          {professional.map((entity) => (
                            <option key={entity.id}>{entity.title}</option>
                          ))}
                        </optgroup>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>
                </div>
                {amountToPay > 0 && (
                  <div className="row">
                    <center>
                      <div className="mb-3 col-md-8 col-12">
                        <label
                          htmlFor="couponCode"
                          className="form-label font-weight-bolder"
                        >
                          <h4>Coupon Code</h4>
                        </label>
                        <div className="d-flex">
                          <input
                            type="text"
                            className={`form-control ${getInputClasses(
                              "couponCode"
                            )}`}
                            id="validationCustom01"
                            placeholder="Enter Your Coupon Code"
                            required
                            {...formik.getFieldProps("couponCode")}
                            readOnly={couponValue}
                          />
                          {!couponValue ? (
                            <button
                              type="button"
                              id="couponCode11"
                              className="btn btn-secondary ml-3"
                              onClick={() => verifyCouponCode(price, true)}
                              disabled={couponValue}
                            >
                              Apply
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="invalid-feedback">
                          {formik.errors["couponCode"]}
                        </div>
                        <div className="mt-4">
                          <div>
                            Currency: <b>{defCurrency || "USD"}</b>
                          </div>
                          <div>
                            Gross Total:{" "}
                            <b>
                              {Number(price)?.toLocaleString("en-US", {
                                style: "currency",
                                currency: defCurrency || "USD",
                                minimumFractionDigits: 0,
                              })}
                            </b>
                          </div>
                          <div>
                            Coupon Value(
                            {coup?.coupon_value
                              ? coup?.coupon_type === "fixed"
                                ? coup?.coupon_value
                                : coup?.coupon_value + "%"
                              : ""}
                            ):{" "}
                            <b>
                              {Number(couponValue || 0)?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: defCurrency || "USD",
                                  minimumFractionDigits: 0,
                                }
                              )}
                            </b>
                          </div>
                          {selectedState ? (
                            <div>
                              {selectedState.name} : {selectedState.percentage}%
                              <br />
                              {!selectedState.inclusive ? (
                                <>
                                  Total to Pay:
                                  {(
                                    Number(amountToPay) +
                                    (selectedState.percentage *
                                      Number(amountToPay)) /
                                      100
                                  )?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: defCurrency || "USD",
                                    minimumFractionDigits: 0,
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <div>
                              Total to Pay:{" "}
                              <b>
                                {Number(amountToPay)?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: defCurrency || "USD",
                                  minimumFractionDigits: 0,
                                })}
                              </b>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 text-center">
                        <img
                          onClick={formik.handleSubmit}
                          style={{
                            width: "15rem",
                            cursor: "pointer",
                            opacity: loading ? 0.5 : 1,
                          }}
                          alt="Stripe"
                          src="/assets/images/card.jpeg"
                        ></img>
                        <br />
                        Pay with Stripe
                      </div>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={modalCleanup}
                // onClick={() => {
                //   setAccepted(false);
                //   setShowUserForm(false);
                // }}
                // data-dismiss="modal"
                // id="closeFormModal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={formik.handleSubmit}
              >
                {amountToPay > 0 ? "Pay Now" : "Submit"}
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

export default TermsCondition;
