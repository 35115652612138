import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { Head } from "../components/Coach";
import { useSelector, shallowEqual } from "react-redux";
import {
  updatePageData,
  deletePageData,
  verifyCoupon,
  checkCoupon,
  findTaxrates,
} from "./_redux/pagesCrud";
import swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createLead } from "./_redux/pagesCrud";
import queryString from "query-string";
import countries from "../../../data/countries.json";
import axios from "axios";
import Navigation from "./Nav";
import Home from "./Home";
import TermsCondition from "./TermsCondition";
import Home2 from "./Home2";
import "../Associate/components/pricing.css";
import OptionsModal from "./components/OptionsModal";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First Name is required"),
  lastName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  confirm_email: Yup.string()
    .required("Confirm email is required")
    .email("Confirm email must be a valid email address")
    .oneOf([Yup.ref("email"), null], "Emails must match"),
  program: Yup.string().required("Program is required"),
  source: Yup.string().required("This field is required"),
  phoneNumber: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Phone is required"),
  country: Yup.string().required("Country is required"),
  province: Yup.string().required("Province is required"),
  city: Yup.string().required("City is required"),
  referral: Yup.string(),
});
export default function Pricing(props) {
  const pageLocation = useLocation();
  // let cLocation = pageLocation.pathname.substring(1).split("/")[1];

  // let queries = queryString.parse(window.location.search);
  const [defCurrency, setDefCurrency] = useState("USD");
  const [page, setPage] = useState();
  const [editButton, setEditButton] = useState(false);
  const [resetButton, setResestButton] = useState(true);
  const [price, setPrice] = useState();
  const [amountToPay, setAmountToPay] = useState();
  const [couponValue, setCouponValue] = useState(0);
  const [priceData, setPriceData] = useState();
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [referral, setReferral] = useState(false);
  const [coup, setCoup] = useState();
  const [states, setStates] = useState();
  const [halfPrice, setHalfPrice] = useState(false);
  const [switchAdded, setSwitchAdded] = useState(false);
  const [sell, setSell] = useState();
  const [professional, setProfessional] = useState([]);
  const [standard, setStandard] = useState([]);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [query_coupon, setQueryCoupon] = useState(null);

  const init = {
    firstName: "",
    lastName: "",
    email: "",
    confirm_email: "",
    companyName: "",
    phoneNumber: "",
    couponCode: "",
    program: "",
    country: "",
    source: "",
    // country: cLocation === "canada" ? "CA" : "",
    province: "",
    city: "",
    route: "",
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: init,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      values.companyName = page.title;
      values.price = price;
      values.amount_to_pay = amountToPay;
      values.page_id = page.id;
      values.price_type = priceData;
      values.half_price = halfPrice;
      values.defCurrency = defCurrency;
      if (selectedState && !selectedState.inclusive)
        values.tax_rate_id = selectedState.tax_rate_id;
      // Number(amountToPay) +
      // (selectedState.percentage * Number(amountToPay)) / 100
      setLoading(true);
      createLead(values)
        .then((result) => {
          if (result.status !== 200) throw result;
          resetForm();
          modalCleanup();
          swal.fire(
            "",
            `Your registration was successful, an email will be sent to you shortly`,
            "success"
          );
          if (result.data.data?.payment_url)
            window.location.href = result.data.data?.payment_url;
          setLoading(false);
          setCouponValue(0);
        })
        .catch((e) => {
          swal.fire(
            "Oops",
            e.response?.data?.message || "Error uploading data",
            "error"
          );
          setLoading(false);
        });
    },
  });
  useEffect(() => {
    if (formik.values.country)
      findTaxrates({
        filter: {
          status: true,
          country: formik.values.country,
          business_id: props.page.business_id, //
        },
      })
        .then((res) => {
          setStates(res.data?.data?.entities);
          formik.values.province = "";
        })
        .catch((e) => setStates([]));

    if (formik.values.country) {
      if (formik.values.country === "CA") {
        setDefCurrency("CAD");
      } else {
        setDefCurrency("USD");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country]);
  const selectedState = useMemo(
    () => states?.find((s) => s.state === formik.values.province),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.province]
  );
  useEffect(() => {
    setPage(props.page);
    if (props.page.business_id) {
      axios
        .get(
          "https://apiteach.singlelogin.io/api/programs/?queryParams={%22filter%22:{%22status%22:%22draft%22,%22search_text%22:%22SPC9%22}}",
          { headers: { businessid: props.page.business_id } }
        )
        .then((response) => {
          if (response.status === 200) {
            setStandard(response?.data?.data?.entities);
          }
        });
    }
  }, [props.page]);

  useEffect(() => {
    if (document.querySelector('input[name="payment"]') && !switchAdded) {
      document.querySelectorAll('input[name="payment"]').forEach((elem) => {
        elem.addEventListener("change", function (event) {
          var item = event.target.id;
          if (item === "half") setHalfPrice(true);
          else if (item === "full") setHalfPrice(false);
        });
      });
      setSwitchAdded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referral]);

  // useEffect(() => {
  //   queries.code && formik.setFieldValue("couponCode", queries.code);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [queries.code]);
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const verifyCouponCode = (pr, loud) => {
    if (formik.values.couponCode) {
      let coupon_value,
        new_price = pr || price,
        res;
      setLoading(true);
      verifyCoupon(
        {
          code: formik.values.couponCode,
          amount: new_price,
        },
        page.business_id
      )
        .then((result) => {
          if (result.status === 200) {
            loud && toast.success("Coupon applied!");
            res = result.data.data;
            coupon_value = res.coupon_value;
            if (res.coupon_type === "percentage")
              coupon_value = (res.coupon_value * new_price) / 100;
            new_price -= coupon_value;
            formik.setFieldValue("coupon_id", res.id);
            formik.setFieldValue("coupon_code", formik.values.couponCode);
          } else {
            // swal.fire("Oops", result.response.data.message, "error");
            toast.error(result.response.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          setCoup(res);
          setAmountToPay(new_price);
          setCouponValue(coupon_value);
        })
        .finally(() => {
          setCoup(res);
          setAmountToPay(new_price);
          setCouponValue(coupon_value);
          setLoading(false);
        });
    } else toast.warn("Please enter your referral code");
  };

  const modalCleanup = () => {
    setAccepted(false);
    setShowOptionsModal(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  Head();
  useEffect(() => {
    document.getElementById("splashScreenButton").click();
    // if (page_slug !== 'canada') {
    // 	const i = countries.findIndex((c) => c.value === 'CA')
    // 	countries.splice(i, 1)
    // }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(pageLocation.search);
    const code = queryParams.get("code");
    if (code) {
      setQueryCoupon(code); // Update state with the code
      formik.setFieldValue("couponCode", code); // Update formik value
    }
  }, [pageLocation.search, formik]);

  const getCouponCode = useCallback(() => {
    return query_coupon;
  }, [query_coupon]); // couponCode in dependency array
  return (
    <>
      {referral && (
        <>
          <Navigation page={page} />

          {/* <Home /> */}

          <Home
            amountToPay={amountToPay}
            setShowOptionsModal={setShowOptionsModal}
          />
        </>
      )}

      <OptionsModal
        showOptionsModal={showOptionsModal}
        setSell={setSell}
        setShowOptionsModal={setShowOptionsModal}
        setAccepted={setAccepted}
        page={page}
        halfPrice={halfPrice}
        sell={sell}
        setPrice={setPrice}
        setAmountToPay={setAmountToPay}
        referral={referral}
        amountToPay={amountToPay}
        priceData={priceData}
        setPriceData={setPriceData}
        couponValue={couponValue}
        setCouponValue={setCouponValue}
        formik={formik}
        verifyCouponCode={verifyCouponCode}
        modalCleanup={modalCleanup}
        hideOptionModal={() => setShowOptionsModal(false)}
      />

      <TermsCondition
        accepted={accepted}
        formik={formik}
        priceData={priceData}
        setAccepted={setAccepted}
        getInputClasses={getInputClasses}
        // cLocation={cLocation}
        modalCleanup={modalCleanup}
        countries={countries}
        states={states}
        amountToPay={amountToPay}
        standard={standard}
        professional={professional}
        couponValue={couponValue}
        verifyCouponCode={verifyCouponCode}
        price={price}
        page={page}
        coup={coup}
        selectedState={selectedState}
        loading={loading}
        defCurrency={defCurrency}
        getCouponCode={getCouponCode}
      />
      <button
        type="button"
        data-toggle="modal"
        data-target="#SplashScreenModal"
        id="splashScreenButton"
        hidden
      ></button>
      <div
        className="modal fade"
        id="SplashScreenModal"
        tabIndex="-1"
        aria-labelledby="SplashScreenModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
        style={{ background: "#261f44!important" }}
      >
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title m-auto" id="SplashScreenModalLabel">
                BlackForce Special Programs Payment
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                hidden
                id="SplashScreenModalClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mx-5 mobile-font-size">
              <p>
                <b>Welcome to Blackforce!!</b>
              </p>
              <p>
                The Blackforce Special Programs consist of carefully selected
                courses designed to meet the demands of the current market,
                specifically targeting experienced professionals.
              </p>

              <p>
                These special programs offer established IT professionals the
                chance to engage with the Blackforce offerings, bypassing the
                typical intensive four-six months training and internship
                requirements.
              </p>

              <p>
                <b>Here is a list of the special programs for this COHORT 9:</b>
              </p>
              <ol>
                <li className="mb-2">
                  <b>
                    Sales Cloud, Service Cloud & Artificial Intelligence - March
                    2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Artificial Intelligence & Integration Development - April
                    2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Certified Business Analysis Professional (CBAP) with
                    Salesforce AI April 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Data Analytics, Tableau & Microsoft Power BI with Salesforce
                    AI - April 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Platform Developer with Salesforce AI - April
                    2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Cybersecurity Incident Responder with Salesforce Artificial
                    Intelligence - April 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    SF. Administrator with On Demand Videos Sales and Service
                    Cloud - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Health Cloud with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Financial Services Cloud with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Nonprofit Cloud with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Marketing Cloud Administrator with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce DevOps Engineering with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Administrator with Salesforce Artificial
                    Intelligence - May 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Education Cloud with Salesforce Artificial
                    Intelligence - June 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Integration Architect and Artificial Intelligence
                    - June 2025
                  </b>
                </li>
                <li className="mb-2">
                  <b>
                    Salesforce Quality Assurance Testing and Artificial
                    Intelligence - June 2025
                  </b>
                </li>
              </ol>
              {/* <p>We're happy to help.</p> */}
              <p>
                *A minimum of 10 students is required for a special program to
                proceed; otherwise, the class will be postponed until the next
                COHORT.
              </p>

              <p>Best of luck in all your endeavors!</p>
              <div className="row">
                <center>
                  <div className="mb-3 col-md-8 col-12">
                    {/* <label
											htmlFor='firstName'
											className='form-label font-weight-bolder'
										>
											<h4>Registration for Cohort 5 is now closed</h4>
										</label> */}
                    <button
                      type="button"
                      className="btn btn-secondary ml-3"
                      onClick={() => {
                        document
                          .getElementById("SplashScreenModalClose")
                          .click();
                        setReferral(true);
                      }}
                    >
                      Continue with Registration
                    </button>
                    {/* <div className='d-flex'>
											<input
												type='text'
												className={`form-control ${getInputClasses(
													'couponCode'
												)}`}
												placeholder='Referral Code Here'
												required
												{...formik.getFieldProps('couponCode')}
											/>
											<button
												type='button'
												className='btn btn-secondary ml-3'
												onClick={() => checkCouponCode()}
												disabled={couponValue}
											>
												{loading ? (
													<>
														<span
															className='spinner-border spinner-border-sm'
															role='status'
															aria-hidden='true'
														></span>
														<span className='sr-only'>Loading...</span>
													</>
												) : (
													'Apply'
												)}
											</button>
										</div> */}
                    <div className="invalid-feedback">
                      {formik.errors["couponCode"]}
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
